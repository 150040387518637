@import '../../assets/styles/_variables.scss';

.container {
	padding: 1rem;
	height: 100%;
	scroll-behavior: smooth;
	background-color: $csm_backgroundColor;
}

.title {
	font-weight: bold;
	text-align: center;
}

.abstract {
	margin-top: 1rem;
}

.info {
	text-align: center;
	margin: 0;
}

.rules {
	height: 350px;
	overflow-y: scroll;
	background-color: $light;

	&::-webkit-scrollbar {
		width: 10px;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: $dark-gray;
	}
}

.button {
	background-color: $dark-gray;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 1rem;
	border-radius: 11px;
	text-decoration: none;
	height: 4rem;
	font-weight: bold;
	color: $kinda-white;
	font-size: 1.5rem;
}

.iconContainer {
	display: flex;
	justify-content: center;
}

.downChevron {
	width: 2rem;
}

.flag {
	width: 3rem;
	position: absolute;
	right: 0;
}

.logo {
	text-align: center;
	display: flex;
}

.emptyDiv {
	width: 65%;
	height: 2rem;
}

.imagesContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 100%;
}

.csm {
	background-color: $csm_primary;
	color: $light;
	border: none;
}

.segurosmundial {
	background-color: $seguros_mundial_primary;
	color: $light;
	border: none;
}

.multinational {
	background-color: $multinational_primary;
	color: $light;
	border: none;
}

.premier {
	background-color: $premier_primary;
	color: $light;
	border: none;
}

.optima {
	background-color: $optima_primary;
	color: $light;
	border: none;
}

.sm_logo {
	height: 6rem;
	margin-bottom: 1rem;
}


.comfenalco {
	background-color: $primary;
	color: $light;
	border: none;
}