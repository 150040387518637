@use "sass:math";
@import '../../assets/styles/variables';

$thumbnail-size: 170px;
$icon-size: 50px;
$gap: 10px;
.simple-camera-screen {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;

	.photos-container {
		flex: 1;
		max-width: 2 * $thumbnail-size;
		padding: 20px;

		display: flex;
		gap: $gap;
		flex-wrap: wrap;

		.image-upload-thumbnail,
		.image-preview-thumbnail {
			height: $thumbnail-size + 60px;
			width: calc(50% - math.div($gap, 2));
			max-width: $thumbnail-size;
			position: relative;

			.custom-file-upload {
				border: 1px solid #ccc;
				border-radius: 10px;
				background: white;
				cursor: pointer;

				height: $thumbnail-size;

				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: center;
				text-align: center;
				gap: 10px;
				padding: 10px;
				box-sizing: border-box;
				font-size: 16px;
				.camera-plus-icon {
					width: $icon-size;
					height: $icon-size;
				}

				.image-container {
					position: relative;
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				.info-icon {
					position: absolute;
					right: 0;
				}

				span{
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 4; /* Número máximo de líneas antes de truncar */
					overflow: hidden;
					text-overflow: ellipsis;
					max-width: 100%;
				}
			}
			.ayuda {
				display: flex;
				justify-content: center;
				align-items: center;

				margin: 10px auto 0;
				width: 80%;
				padding: 6px;
				box-sizing: border-box;

				color: white;
				background: #222;

				border-radius: 50px;
				svg {
					width: 20px;
					margin-right: 6px;
					fill: white;
				}
				span {
					line-height: 20px;
				}
			}
			input[type='file'] {
				display: none;
			}
		}

		.image-preview-thumbnail {
			height: $thumbnail-size;
			border: 1px solid #ccc;
			border-radius: 10px;
			background: white;
			cursor: pointer;
			box-sizing: border-box;
		}
	}

	.confirm-button {
		width: calc(100% - 68px);
		max-width: 2 * $thumbnail-size;
	}

	.full-screen-preview {
		position: fixed;
		height: 100%;
		width: 100%;
		background: #000000dd;
		display: flex;
		flex-direction: column;
		justify-content: space-around;

		img {
			width: 100%;
			max-height: 80%;
		}
		.action-buttons {
			display: flex;
			justify-content: space-between;
			padding: 20px;
			gap: 20px;
		}
	}
}

.csm .simple-camera-screen {
	background-color: $csm_backgroundColor;
	.photos-container .image-upload-thumbnail .ayuda {
		background-color: $csm_primary;
		color: white;
	}
}

.assa .simple-camera-screen {
	background-color: $assa_bg;
	.photos-container .image-upload-thumbnail .ayuda {
		background-color: $assa_primary;
		color: white;
	}
}

.qualitas .simple-camera-screen {
	.photos-container .image-upload-thumbnail .ayuda {
		background-color: $qualitas_primary;
		color: white;
	}
}

.lafise .simple-camera-screen {
	.photos-container .image-upload-thumbnail .ayuda {
		background-color: $lafise_primary;
		color: white;
	}
}

.oceanica .simple-camera-screen {
	.photos-container .image-upload-thumbnail .ayuda {
		background-color: $oceanica_primary;
		color: white;
	}
}

.sura .simple-camera-screen {
	.photos-container .image-upload-thumbnail .ayuda {
		background-color: $sura_primary;
		color: white;
	}
}

.segurosmundial .simple-camera-screen {
	.photos-container .image-upload-thumbnail .ayuda {
		background-color: $seguros_mundial_primary;
		color: white;
	}
}

.multinational .simple-camera-screen {
	background-color: $multinational_backgroundColor;
	.photos-container .image-upload-thumbnail .ayuda {
		background-color: $multinational_primary;
		color: white;
	}
}

.premier .simple-camera-screen {
	background-color: $premier_backgroundColor;
	.photos-container .image-upload-thumbnail .ayuda {
		background-color: $premier_primary;
		color: white;
	}
}

.optima .simple-camera-screen {
	background-color: $optima_backgroundColor;
	.photos-container .image-upload-thumbnail .ayuda {
		background-color: $optima_primary;
		color: white;
	}
}

.photo-help-container {
	position: fixed;
	top: 0;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: rgba($dark, 0.7);
	.modal {
		animation: open 0.3s;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 20px;
		min-width: 250px;
		max-width: 80%;
		min-height: 400px;
		margin: 0 auto;

		background: white;
		border-radius: 10px;
		padding: $padding;

		display: flex;
		flex-direction: column;
		justify-content: space-between;

		h2 {
			text-align: center;
		}
	}
}
.custom-tooltip .MuiTooltip-tooltip {
	font-size: 1.2rem; /* Cambia el tamaño del texto del tooltip */
  }

@keyframes open {
	from {
		bottom: -400px;
	}
	to {
		bottom: 20px;
	}
}
