@import '../../variables';

.header {
	display: flex;
	flex-direction: row;
	padding: $padding;
	.text-container {
		display: flex;
		flex: 1;
		justify-content: center;
		flex-direction: column;
		font-size: 15px;
		padding-right: 4rem;
		h3,
		p {
			margin: 0;
			color: $dark-grey;
		}
		p {
			font-size: 85%;
			margin-top: 6px;
		}
	}
}

.sura .header .text-container {
	padding-right: 4rem;
}

.csm .header .text-container {
	padding-right: 2rem;
}

.lafise .header .text-container {
	padding-right: 1rem;
}

.oceanica .header .text-container {
	padding-right: 1rem;
}

.assa .header .text-container {
	padding-right: 1rem;
}

.segurosmundial .header .text-container {
	padding-right: 4rem;
}

.multinational .header .text-container {
	padding-right: 1rem;
}

.premier .header .text-container {
	padding-right: 2rem;
}

.optima .header .text-container {
	padding-right: 2rem;
}