@import '../variables';

.progress-bar {
	display: flex;
	gap: 10px;
	margin: 10px 10px;

	.bar {
		width: 100%;
		height: 5px;
		background-color: $gray;
	}

	.bar-color {
		background-color: $primary;
	}
}

.sura .bar-color {
	background-color: $sura_primary;
}

.csm .bar-color {
	background-color: $csm_primary;
}

.lafise .bar-color {
	background-color: $lafise_primary;
}

.oceanica .bar-color {
	background-color: $oceanica_primary;
}

.qualitas .bar-color {
	background-color: $qualitas_primary;
}

.assa {
	.bar {
		border-radius: 3px;
	}
	.bar-color {
		background-color: $assa_primary;
	}
}

.segurosmundial .bar-color {
	background-color: $seguros_mundial_primary;
}

.multinational .bar-color {
	background-color: $multinational_primary;
}

.premier .bar-color {
	background-color: $premier_primary;
}

.optima .bar-color {
	background-color: $optima_primary;
}