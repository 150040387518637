@import '../../variables';

.inactive-screen {
	background-image: url(../../imgs/connect/brand_bg.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	text-align: center;

	h1 {
		padding: $padding;
		margin-top: 30px;
		font-size: 22px;
		margin-bottom: 0;
	}
}

.sura .inactive-screen {
	background-image: url(../../imgs/sura/brand_bg.png);
	color: white;
}

.csm .inactive-screen {
	background-image: none;
	background-color: white;
	color: black;
}

.lafise .inactive-screen {
	background-image: none;
	background-color: $lafise_secondary;
	color: #fff;
}

.oceanica .inactive-screen {
	background-image: none;
	background-color: $oceanica_secondary;
	color: #fff;
}

.qualitas .inactive-screen {
	background-image: none;
	background-color: $qualitas_primary;
	color: #fff;
}

.assa .inactive-screen {
	background-image: none;
	background-color: $assa_primary;
	color: #fff;
}

.segurosmundial .inactive-screen {
	background-image: none;
	background-color: $seguros_mundial_primary;
	color: white;
}

.multinational .inactive-screen {
	background-image: none;
	background-color: $multinational_primary;
	color: white;
}

.premier .inactive-screen {
	background-image: none;
	background-color: white;
	color: black;
}

.optima .inactive-screen {
	background-image: none;
	background-color: white;
	color: black;
}