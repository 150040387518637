@import '../../variables';

.success-screen {
	background-image: url(../../imgs/connect/brand_bg.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top;
	height: 100%;

	padding: $padding;
	display: flex;
	justify-content: center;
	align-items: center;

	.container {
		color: white;
		text-align: center;
		h1 {
			margin-top: 30px;
			font-size: 22px;
			margin-bottom: 0;
		}
		h2 {
			margin-top: 30px;
			font-size: 16px;
			margin-bottom: 80px;
		}
		.checkmark {
			height: 98px;
			width: 90px;
		}
		.contact-us {
			margin: 2rem;
			a {
				text-decoration: none;
				color: $font-color;
				font-weight: bold;
			}
		}

		.icons {
			display: flex;
			align-items: center;
			flex-direction: column;

			&__logo {
				width: 200px;
			}
		}
	}
}

.sura .success-screen {
	background-image: url(../../imgs/sura/brand_bg.png);
	.container {
		color: white;
		h1 {
			font-size: 22px;
		}
	}
}

.csm .success-screen {
	background-image: none;
	background-color: $csm_backgroundColor;
	.container {
		color: #000;
		h1 {
			font-size: 19px;
		}
		p {
			font-size: 15px;
		}
	}
}

.lafise .success-screen {
	background-image: none;
	background-color: $lafise_secondary;
	.container {
		color: #fff;
		h1 {
			font-size: 19px;
		}
		p {
			font-size: 15px;
		}
		.checkmark {
			height: 90px;
			width: 90px;
		}
	}
}

.oceanica .success-screen {
	background-image: none;
	background-color: $oceanica_secondary;
	.container {
		color: #fff;
		h1 {
			font-size: 19px;
		}
		p {
			font-size: 15px;
		}
		.checkmark {
			height: 90px;
			width: 90px;
		}
	}
}

.qualitas .success-screen {
	background-image: none;
	background-color: $qualitas_primary;
	.container {
		color: #fff;
		h1 {
			font-size: 19px;
		}
		p {
			font-size: 15px;
		}
		.checkmark {
			height: 90px;
			width: 90px;
		}
	}
}

.assa .success-screen {
	background-image: none;
	background-color: $assa_primary;
	.container {
		color: #fff;
		h1 {
			font-size: 19px;
		}
		p {
			font-size: 15px;
		}
		.checkmark {
			fill: $light;
			height: 90px;
			width: 90px;
			#Trazado_1462 {
				stroke: $light;
			}
		}
	}
}

.segurosmundial .success-screen {
	background-image: none;
	background-color: $seguros_mundial_backgroundColor;
	.container {
		color: $seguros_mundial_secondary;
		h1 {
			font-size: 22px;
		}
	}
}

.multinational .success-screen {
	background-image: none;
	background-color: $multinational_backgroundColor;
	.container {
		color: #000;
		h1 {
			font-size: 19px;
		}
		p {
			font-size: 15px;
		}
	}
}

.premier .success-screen {
	background-image: none;
	background-color: $premier_backgroundColor;
	.container {
		color: #000;
		h1 {
			font-size: 19px;
		}
		p {
			font-size: 15px;
		}
	}
}

.optima .success-screen {
	background-image: none;
	background-color: $optima_backgroundColor;
	.container {
		color: #000;
		h1 {
			font-size: 19px;
		}
		p {
			font-size: 15px;
		}
	}
}