@import '../../variables';

.survey-screen {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&__header {
		h2 {
			color: $dark-grey;
			margin-left: 1rem;
		}
	}

	.survey-container {
		flex: 1;

		.questions {
			margin: 1rem;

			.question {
				display: flex;
				align-items: center;

				&__text {
					font-size: 1.15rem;
				}

				&--comment {
					display: flex;
					flex-direction: column;
				}

				&__rate {
					flex: 1;

					.MuiRating-sizeLarge span {
						font-size: 1.8rem;
					}
				}

				&__image {
					position: relative;
					width: 100px;
				}
			}
		}
	}
}

.rate-divider {
	border: 3px solid $light-gray;
}

.section-divider {
	margin: 1rem 0;
}

.rate-examples {
	margin: 1rem;
	display: flex;
	justify-content: space-between;

	.rate-example {
		display: flex;
		align-items: center;

		.rate-label {
			margin-left: 0.5rem;
			display: block;
		}

		.MuiRating-icon.MuiRating-iconEmpty {
			display: none;
		}
	}
}

.MuiRating-sizeLarge span {
	font-size: 1.5rem;
}

.connect {
	.MuiRating-root {
		color: $primary;
	}
}

.sura {
	.MuiRating-root {
		color: $sura_primary;
	}
}

.csm {
	.MuiRating-root {
		color: $csm_primary;
	}
}

.lafise {
	.MuiRating-root {
		color: $lafise_primary;
	}
}

.oceanica {
	.MuiRating-root {
		color: $oceanica_secondary;
	}
}

.qualitas {
	.MuiRating-root {
		color: $qualitas_primary;
	}
}

.assa {
	.MuiRating-root {
		color: $assa_primary;
	}
}

.segurosmundial {
	.MuiRating-root {
		color: $seguros_mundial_primary;
	}
	.MuiIconButton-label {
		color: $seguros_mundial_primary;
	}
	.MuiSlider-root {
		color: $seguros_mundial_primary;
	}
}

.multinational {
	.MuiRating-root {
		color: $multinational_primary;
	}
}

.premier {
	.MuiRating-root {
		color: $premier_primary;
	}
}

.optima {
	.MuiRating-root {
		color: $optima_primary;
	}
}


.comfenalco {
	.MuiRating-root {
		color: $primary;
	}
	.MuiIconButton-label {
		color: $primary;
	}
	.MuiSlider-root {
		color:  $primary;
	}
}