@import '../../variables';

.summary-screen {
	height: 100%;
	overflow: scroll;
	.container {
		display: flex;
		flex-direction: column;
		margin: 0 $padding;

		.summary-row {
			display: flex;
			padding-left: 16px;
			margin: 16px;
			margin-top: 8px;

			.icon {
				height: 20px;
				width: 20px;
				margin-right: 16px;
			}

			.description {
				flex-grow: 1;
				padding-bottom: 16px;
				border-bottom: 1px solid $light-gray;

				.subtext {
					margin-top: 8px;
					color: $dark-gray;
				}
			}
		}

		h1 {
			font-size: 18px;
			margin-top: 16px;
		}
		h2 {
			font-size: 16px;
			margin-top: 0;
		}

		.img-container {
			display: flex;
			flex-wrap: wrap;

			img {
				object-fit: cover;
				height: 80px;
				width: 80px;
				background: $light-gray;
				margin-right: $padding;
				margin-bottom: $padding;
			}
		}
	}
}

.csm .summary-screen {
	background-color: $csm_backgroundColor;
}

.assa .summary-screen {
	background-color: $assa-bg;
}

.multinational .summary-screen {
	background-color: $multinational_backgroundColor;
}

.premier .summary-screen {
	background-color: $premier_backgroundColor;
}

.optima .summary-screen {
	background-color: $optima_backgroundColor;
}