@import '../../variables';

button.damage-check {
	margin: 6px;
	padding: 3px;
	font-size: inherit;

	background-color: $light;
	color: $dark;
	width: 32px;
	height: 32px;
}
button.damage-check.selected {
	background-color: $dark;
	color: $light;
}

.oceanica button.damage-check.selected {
	background-color: $oceanica_secondary;
	color: $light;
	border: 1px solid $oceanica_secondary;
}

.qualitas button.damage-check.selected {
	background-color: $qualitas_primary;
	color: $light;
	border: 1px solid $qualitas_primary;
}

.csm button.damage-check.selected {
	background-color: $csm_primary;
	color: $csm_backgroundColor;
	border: 1px solid $csm_backgroundColor;
}

.multinational button.damage-check.selected {
	background-color: $multinational_primary;
	color: $multinational_backgroundColor;
	border: 1px solid $multinational_backgroundColor;
}

.premier button.damage-check.selected {
	background-color: $premier_primary;
	color: $premier_backgroundColor;
	border: 1px solid $premier_backgroundColor;
}

.optima button.damage-check.selected {
	background-color: $optima_primary;
	color: $optima_backgroundColor;
	border: 1px solid $optima_backgroundColor;
}