@import '../../variables';

.sura .map {
	&__gpsOff {
		background-color: $sura_primary;
	}
}

.csm .map {
	&__gpsOff {
		background-color: $csm_primary;
	}
}

.lafise .map {
	&__gpsOff {
		background-color: $lafise_primary;
	}
}

.oceanica .map {
	&__gpsOff {
		background-color: $oceanica_primary;
	}
}

.qualitas .map {
	&__gpsOff {
		background-color: $qualitas_primary;
	}
}

.assa .map {
	&__gpsOff {
		background-color: $assa_primary;
	}
}

.segurosmundial .map {
	&__gpsOff {
		background-color: $seguros_mundial_primary;
	}
}

.multinational .map {
	&__gpsOff {
		background-color: $multinational_primary;
	}
}

.premier .map {
	&__gpsOff {
		background-color: $premier_primary;
	}
}

.optima .map {
	&__gpsOff {
		background-color: $optima_primary;
	}
}